.todos-prog-bar-section {
    display: flex;
    align-items: center;
    margin-block-start: 8px;
    margin-block-end: 5px;

    .todos-prog-bar-percentage {
        margin-inline-start: 3px;
        font-size: 11px;
        width: 32px;
        text-align: center;
    }

    .todos-prog-bar-background {
        background-color: $t-hover3;
        color: #111;
        position: relative;
        height: 8px;
        width: 100%;
        border-radius: 0.5em;
        margin-left: 5px;

        .prog-accomplished {
            transition: background-color 0.3s, width 0.15s;
            border-radius: 0.5em 0 0 0.5em;
            width: 100%;
            height: 100%;
            background-color: #5ba4cf;

            &.isComplite {
                border-radius: 0.5em;
                background-color: #61bd4f;
            }
        }
    }
}