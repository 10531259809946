.side-bar-menu {
    margin-top: 8px;

    h3 {
        margin-top: 9px;
        margin-bottom: 0px;
        font-size: 12px;
        word-spacing: -0.99px;
    }

    .btn-side-bar {
        margin-top: 6px;
        display: block;
        width: 168px;
        display: flex;
        align-items: center;
        padding-inline-start: 6px;

        // in response max-with 300px
        &:first-of-type {
            margin-top: 3px;
        }
    }

    .btn-side-bar-icon {
        margin-inline-end: 6px;
        width: 20px;
        height: 20px;
    }

    .btn-remove-side-bar {
        background: #b04632;
        color: $clr6;
        transition: all 150ms ease-out 0s;

        &:hover {
            background-color: #933b27;
        }
    }

    .btn-side-bar-icon-label {
        transform: rotate(270deg)
    }

    .task-side-bar-hr {
        margin: 8px 0;
        background-color: #091e4221;
        border: 0;
        height: 1px;
        padding: 0;
        width: 95%;
    }

    .side-bar-menu-second-section {
        margin-top: 45px;
    }
}