.edit-label-modal {


    .btn-edit-label-modal-back-container {
        position: absolute;
        top: 10px;
        color: $t-txt1;

        .btn-edit-label-modal-back {
            font-size: 18px;

        }
    }

    .title-edit-label {
        box-shadow: inset 0 0 0 2px #dfe1e6;
        background-color: #fafbfc;
        width: 100%;
        font-size: 14px;
        padding: 8px 12px;
        margin-block-end: 10px;
    }

    .edit-label-modal-mini-title {
        margin: 0;
    }

    .colors {
        margin-top: 12px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(9, 30, 66, 0.1294117647);

        .color-wrapper {
            -webkit-transform: translate3d(0, 0, 0);
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;
            padding: 4px;
            margin: 0 -4px;
            overflow-x: hidden;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

            .color-btn {
                margin: 0;
                border-radius: 3px;
                height: 72px;
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                box-shadow: none;
                width: 50px;
                height: 32px;
                cursor: pointer;
                border: none;
            }
        }
    }


}

.btn-create-label {
    width: 100%;
}