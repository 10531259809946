.task-details {

    .black-screen {
        position: fixed;
        inset: 0;
        background-color: #121212db;
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.6s;
        pointer-events: none;
        z-index: 10;
        box-shadow: -6px 0px 14px rgb(0 0 0 / 20%);
        pointer-events: auto;
        opacity: 1;
        // max-height: 100%;
        left: 0;
        top: 0;
        height: 100vh;
        display: flex;
        justify-content: center;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 10px;
            padding-top: 20px;

        }

        &::-webkit-scrollbar-thumb {
            background-color: #BFC4CE;
            border-radius: 30px;
            height: 12px;
        }

        &::-webkit-scrollbar-track {
            background-color: #D9DCE2;
            border-radius: 30px;
            height: 12px;
            border-bottom: 10px solid transparent;
        }

    }


    .task-details-section {
        left: 50%;
        z-index: 40;
        font-family: segoe_ui;
        background-color: $t-bg1;
        border-radius: 2px;
        // width: 90%;
        max-width: 784px;
        min-width: 700px;
        // min-height: 801px;
        margin-inline: auto;
        height: fit-content;
        letter-spacing: 0.03em;
        font-size: 14px;
        color: $t-txt1;
        margin-top: 48px;
        margin-bottom: 80px;
        display: grid;
        grid-template-columns: 16px 1fr 16px;
        grid-template-rows: minmax(0, max-content) 1fr;
        position: static;
        padding-bottom: 30px;
        cursor: default;



        .task-cover {
            grid-row: 1;
            height: 116px;
            width: 100%;
            grid-column: 1/4;

            &.img {
                height: 160px;
            }

            .btn-task-cover {
                z-index: 1000;
                position: relative;
                float: right;
                margin: 10px 8px;
                display: flex;
                gap: 8px;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                color: #172b4d;
                padding: 8px;
                transition: all 150ms ease-out 0s;

                span {
                    transform: rotate(270deg)
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }

        }

        .task-details-main-section {
            grid-column: 2;
            grid-row: 2;
            display: grid;
            grid-template-rows: 84.5px 1fr;


            .task-title-container {
                grid-row: 1/2;
            }

            .task-details-container {
                grid-row: 2/3;
            }

        }

        .task-details-container {
            display: grid;
            grid-template-columns: 1fr 167px;
            gap: 18px;

            .task-details-edit-section {
                grid-column: 1/2;
            }

            .side-bar-menu {
                grid-column: 2/3;
            }
        }

        .task-details-edit-section {
            display: grid;
            grid-template-rows: repeat(4, minmax(0, max-content));
            gap: 17px;
            // margin-inline-end: 20px;
        }

        textarea,
        input {
            // font-family: inherit;
            resize: none;
            // background-color: inherit;
            border: none;
            // border-radius: 3px;
            // margin: 0px;
            cursor: pointer;

            color: $t-txt1;
            letter-spacing: 0.03em;

            &:focus-visible {
                outline: none;
            }

            &:focus {
                border: none;
                // box-shadow: rgb(0, 121, 191) 0px 0px 0px 2px inset;
                background-color: $t-bg3;
            }
        }
    }

    .btn-task-exit {
        width: 44px;
        height: 44px;
        position: absolute;
        justify-self: end;
        border-radius: 50%;
        display: flex;
        color: #42526e;
        padding: 4px;
        margin: 4px;
        transition: all 150ms ease-out 0s;

        &:hover {
            background-color: $t-hover3;
        }

        &.close-hover {
            background-color: $t-hover3;
        }

        .exit-icon {
            width: 20px;
            height: 20px;
            line-height: 32px;
            padding: 0;
            margin: auto;
            font-size: 14px;
        }
    }

    .small-headline {
        font-size: 12px;
        color: #66748b;
        font-family: segoe_ui;
        letter-spacing: 0.04em;
        width: fit-content;
        cursor: text;
        @include margin-titles-task
    }

    .medium-headline {
        font-size: 16px;
        color: #2d3f5d;
        margin: 0;
        font-family: segoe_ui;
        font-weight: bold;
        letter-spacing: 0.03em;
        cursor: text;
    }


    .btn-task-details {
        padding-inline: 12px;
        padding-block: 6px;
        color: $t-txt1;
        background-color: $t-bg5;
        border-radius: 3px;
        transition: all 150ms ease-out 0s;

        &:hover {
            background-color: $t-hover3;
        }
    }

    .task-details-edit-item {
        display: flex;
        align-items: flex-end;
        padding-inline-start: 40px;
        flex-wrap: wrap;
    }

    .icon-task {
        width: 32px;
        height: 32px;

    }

}