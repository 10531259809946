.cmp-dynamic-option-member {
    color: $t-txt1;

    .cmp-dynamic-member-img {
        width: 32px;
        height: 32px;
        margin-inline-end: 8px;
        margin-block: 4px;
        border-radius: 50%;
    }

    .cmp-dynamic-member-name {
        flex-grow: 1;
        font-size: 14px;
    }

    .cmp-dynamic-member-checked-icon-container {
        width: 42px;
        height: 42px;
        padding: 11px;

        .cmp-dynamic-member-checked-icon {
            width: 16px;
            height: 16px;
        }
    }

    &:hover {
        background-color: $t-bg5;
        border-radius: 3px;
    }
}