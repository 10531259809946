// Colors
// DELETE LATER
$clr1: #ce2e6c;
$clr2: #4f3a65;
$clr3: #574f7d;
$clr4: #95adbe;
$clr5: #dff0ea;
$clr6: #ffffff;
$clr7: #222;

// Homepage Header
$hh-bg1: #ffffff;
$hh-bg2: #0C62DD;
$hh-txt1: #172B4D;
$hh-txt2: #ffffff;
$hh-txt3: #344562;


// Homepage
$h-txt1: #ffffff;
$h-txt2: #172B4D;
$h-txt3: #344562;
$h-btn1: #0C62DD;
$h-btn2: #172B4D;

// Homepage Footer
$hf-txt1: #ffffff;
$hf-btn1: #172B4D;

// Workspace Header
$wh-bg1: #046AA7;
$wh-bg2: #064A74;
$wh-txt1: #ffffff;


// Workspace
$w-bg1: #F9FBFC;

$w-txt1: #172B4D;
$w-txt2: #0C62DD;
$w-txt3: #5E6C84;

$w-icon1: #43516E;

$w-hover1: #EAECF0;

// Board Header
$bh-txt1:#ffffff;
$bh-txt2:#172B4D;

// Board
$b-txt1: #172B4D;
$b-txt2: #ffffff;
$b-txt3: #5E6C84;

// Task
$t-bg1: #f4f5f7;

$t-bg2: #EAECF0;
$t-bg3: #ffffff;
$t-bg4: #F3F5F7;
$t-bg5: rgba(9, 30, 66, 0.04);

$t-btn-bgc: #0079bf;

$t-txt1: #172B4D;
$t-txt2: #5E6C84;
$t-txt3: #748195;

$t-pls-clr: #8791a3;


$t-hover1: #EAECF0;
$t-hover2: #F3F5F7;
$t-hover3: rgba(9, 30, 66, 0.08);



// Breakpoints
$mobile-breakpoint: 460px;
$narrow-breakpoint: 600px;
$normal-breakpoint: 960px;