.board-preview {
    height: 96px;
    width: 178px;
    border-radius: 3px;
    margin: 0 10px;
    padding: 8px;
    margin: 8px;
    cursor: pointer;
    color: white;
    font-weight: 700;
    font-size: 16px;
    background-color: rgb(0 121 191);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    img {
        object-fit: cover;
    }

    .preview-fade {
        background-color: #00000030;
        border-radius: 3px;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        &:hover {
            background-color: #00000043;
        }

        .preview-details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 8px;

            .preview-board-not-starred {
                opacity: 0;
            }

            &:hover{
                .preview-board-not-starred {
                    opacity: 1;
                    background-color: none;
                    border: none;
                    outline: none;
                    align-self: end;
                    color:#ffffff;
                    height: 20px;
                    svg {
                        height: 16px;
                        line-height: 16px;
                        width: 16px;
                        stroke-width:1.3;
                        transition-duration: .15s;
                        transition-property: color,background,opacity,transform,width,margin;
                    }
                    &:hover {
                        svg {
                            height: 18px;
                            line-height: 18px;
                            width: 18px;
                            stroke-width:2;
                        }
                    }
                }
            }

            .preview-board-starred {
                background-color: none;
                border: none;
                outline: none;
                align-self: end;
                color:#f2d600;
                height: 20px;
                svg {
                    height: 16px;
                    line-height: 16px;
                    width: 16px;
                    stroke-width:1.3;
                    path {
                        fill:#f2d600;
                    }
                }

                &:hover {
                    color:#f2d600;
                    svg {
                        height: 16px;
                        line-height: 16px;
                        width: 16px;
                        stroke-width:2.5;
                        path {
                            fill:none;
                        }
                    }
                }
            }

            
        }
    }
}