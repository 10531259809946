.task-preview {
    padding-bottom: 8px;
    overflow: hidden;
    margin: 4px;
    max-width: 100%;
    position: relative;
    background-color: $t-bg3;
    border-radius: 3px;
    box-shadow: 0 1px 0 #091e4240;
    font-family: segoe_ui;
    font-size: 14px;
    white-space: normal;
    cursor: pointer;
    padding-bottom: 2px;

    .task-preview-cover {
        height: 32px;
        border-radius: 3px 3px 0 0;
        display: block;
        position: static;
        padding: 0;

        &.img {
            height: 163px;
        }
    }

    .edit-btn {
        border: none;
        background-color: transparent;

        position: absolute;
        top: 0px;
        right: 0px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: segoe_ui;
        margin: 2px 2px;
        padding: 3px;
        border-radius: 3px;

        opacity: 0;
        transition: all 150ms ease-out 0s;


        svg {
            height: 16px;
            width: 16px;
            border-radius: 3px;
            border: 0;
            align-items: center;
            background-color: transparent;
            margin: 3px;
            white-space: nowrap;

            path {
                fill: $t-txt3;
            }
        }

        &:hover {
            background-color: #83818136;

            path {
                fill: #172B4D;
            }
        }
    }

    &:hover {
        background-color: $t-hover2;

        .edit-btn {
            opacity: 100%;
        }
    }

    .task-preview-label-container {
        display: flex;
        gap: 2px;

        .task-preview-label {
            margin: 4px 0;
            padding-right: 0;
            padding-left: 0;
            width: 40px;
            height: 8px;
            border: none;
            border-radius: 4px;
            margin-inline-end: 3px;
            transition: all 150ms ease-out 0s;

            &:hover,
            :focus {
                filter: saturate(85%) brightness(92%);
            }
        }
    }

    .task-preview-details {
        padding: 4px 8px;

        .task-preview-member-container {
            padding: 0;
            display: flex;
            flex-direction: row;
            gap: 2px;

            .member-img {
                border-radius: 50%;
            }
        }

        p {
            margin-block: 0;
        }

        .task-title {
            word-wrap: break-word;
            clear: both;
            color: $t-txt1;
            display: block;
            overflow: hidden;
            padding: 6px 0;
        }

        .task-preview-actions-wrapper {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            .task-preview-actions {
                display: block;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                float: left;
                margin-left: -2px;
                max-width: 100%;
                margin-top: 4px;

                .date {
                    color: #6b778c;
                }

                p {
                    font-size: 11px;
                    padding-left: 2px;
                }

                .due-sticker,
                .todosDone {
                    border-radius: 3px;
                    padding: 4px 2px 2px 2px;
                    margin-inline-end: 4px;

                    p {
                        color: #fafbfc;
                    }

                }

                .completed {
                    background-color: #7bc86c;


                    svg {
                        color: #fafbfc;
                    }

                    &:hover {
                        background-color: #519839
                    }
                }

                .overdue {
                    background-color: #ec9488;

                    svg {
                        color: #fafbfc;
                    }

                    &:hover {
                        background-color: #eb5a46;
                    }
                }

                .soon {
                    background-color: #f5dd29;

                    svg {
                        color: #fafbfc;
                    }

                    &:hover {
                        background-color: #baa92d;
                    }
                }

                .todosDone {
                    margin: 0 2px;
                    background-color: #7bc86c;

                    p {
                        color: #fafbfc;
                    }

                    svg {
                        color: #fafbfc;
                    }
                }

                .task-preview-actions-icons {
                    color: #6b778c;
                    margin: 0 4px 4px 0;
                    overflow: hidden;
                    padding: 2px;
                    position: relative;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    vertical-align: top;
                    display: flex;
                    align-items: center;
                    padding: 0 4px;

                    .date {}

                    .desc {}

                    .comment {}

                    .attach {}

                    .check {}
                }
            }
        }
    }
}