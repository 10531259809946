.workspace-section {
    color: #172b4d;
    margin: auto;
    margin-top: 40px;
    max-width: 825px;
    min-width: 288px;
    flex-grow: 1;

    .boards-section-header {
        display: flex;
        align-items: center;
        height: 38px;
        margin-left: 11px;

        .boards-section-header-icon {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            color: #172b4d;
        }

        .boards-section-header-name {
            padding-left: 10px;

            h3 {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .board-list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;


        li {
            margin-bottom: 16px;
        }

        .create-new-board {
            background-color: #f0f2f5;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: #172b4d;
                line-height: 20px;
                font-weight: 400;
                font-size: 14px;
            }

            &:hover {
                background-color: #e7e9ed;
            }
        }
    }
}