.task-activities {
    padding: 8px 0px;
    max-width: 558px;
    font-family:'segoe_ui';
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: auto;

    .icon-activities {
        padding-block-start: 3px;
        width: 28px;
        height: 28px;
        align-self: center;
        margin: 8px 0 12px 0;
        svg {
            path {
                stroke-width: 0.5;
            }
        }
    }

    .task-activities-header {
        height: 32px;
        margin-top: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        border-radius: 3px;
        margin-bottom: 12px;
        width: 100%;
        color: #172b4d;
    }

    .member-img {
        height: 32px;
        width: 32px;
        object-fit: cover;
        border-radius: 50%;
        img {
            width: 100%;
            border-radius: 25em;
        }
    }

    form {
        width: 100%;
    }

    .new-comment-container {
        width: 100%;
        height: fit-content;
        background-color: white;
        border-radius: 3px;
        flex: 1;
        margin: 0;
        box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
        transition-duration: 85ms;
        transition-property: height, box-shadow;
        transition-timing-function: ease;
        &:focus {
            box-shadow: 0 4px 8px -2px #091e4240, 0 0 0 1px #091e4214;
        }
        &::placeholder {
            color: #5e6c84;
        }

        textarea {
            font-family:'segoe_ui';
            overflow: hidden;
            overflow-wrap: break-word;
            width: 100%;
            padding: 8px 12px;
            background-color: white;
            border-radius: 3px;
            flex: 1;
            margin: 0;
        }

        button.btn-save-comment {
            height: 32px;
            border-radius: 3px;
            border:none;
            margin-left: 12px;
            padding:3px 12px;
            background-color: #0079bf;
            color: white;
            &:hover {
                background-color: #026aa7;
            }
            &:active {
                background-color: #055a8c;
            }
            &:disabled {
                background-color: #091e420a;
                color: #a5adba;
                cursor: not-allowed;
            }
        }
    }
    

    .task-activity-list {
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        margin-top: 12px;
        grid-column: 1/3;

        .activity-preview {
            width: 100%;
            display: grid;
            grid-template-columns: 40px 1fr;
            color: #172b4d;

            .preview-member-img {
                width: 32px;
                border-radius: 50%;
            }

            .activity-description {
                word-break: break-word;
                line-height: 20px;
            
                .username {
                    font-weight: 700;
                    font-size: 14px;
                    margin-inline-end: 3px;
                    color: #172b4d;
                }

                .time {
                    font-weight: 400;
                    font-size: 12px;
                    color: #5e6c84;
                    margin-left: 4px;
                }
            
                .comment {
                    margin-top: 3px;
                    width: 100%;
                    min-height: 36px;
                    border-radius: 3px;
                    box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
                    background-color: white;
                    padding: 8px 12px;
                    margin-bottom: 6px;
                    color: #172b4d;
                }
            }
        }
    }
}
