.task-checklists-preview-section {}

.task-checklists-preview-container {
    margin-block-end: 40px;

    &:last-of-type {
        margin-block-end: 0px;

    }

    .checklists-icon-container {
        padding-block-start: 7.5px;
        padding-inline-start: 6px;
        align-self: flex-start;
    }

    .checklists-icon {
        width: 21px;
        height: 21px;
        justify-self: center;
    }
}

.task-checklist-preview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.task-checklist-preview-header-icon-and-title {
    display: flex;
    align-items: center;
}

.task-checklist-preview-title-container {

    margin-inline-start: 15px;
    font-size: 12px;
    flex-grow: 1;

    .task-checklist-title {
        cursor: pointer;
    }

    .task-checklist-title-input {

        .task-checklist-title-input {
            background-color: $t-bg5;
            box-shadow: rgba(37, 117, 255, 0.13) 0px 0px 0px 1px inset;
            padding: 8px 12px;
            width: 512px;
            height: 54px;
            border-radius: 3px;

            &:focus {
                box-shadow: rgba(9, 30, 66, 0.13) 0px 0px 0px 1px inset !important;
                background-color: $t-bg5;
            }
        }
    }

    .task-checklist-btn {
        display: flex;
        align-items: center;
    }

    .btn-checklist-save {
        background-color: $t-btn-bgc;
        color: $clr6;
        margin-inline-end: 4px;
        width: 52px;
        height: 32px;
        font-size: 14px;
        padding: 4px 12px 6px;


        &:hover {
            background-color: #026aa7;
        }
    }

    .btn-checklist-cancel-container {

        .btn-checklist-cancel {
            width: 18px;
            height: 18px;
            color: #42526e;
            vertical-align: bottom;

            path {
                stroke: #42526e;
                stroke-width: 10%;
            }
        }
    }

}

.task-add-todo-container {
    margin-block-start: 8px;
    margin-inline-start: 40px;


    .task-add-todo-input {
        box-shadow: inset 0 0 0 2px #0079bf;
        background-color: $clr6 ;
        height: 56px;
        padding: 8px 12px;
        width: 100%;
        font-size: 14px;
        border-radius: 3px;
    }

    .btn-add-todo {

        // margin-block-end: 40px;

        // &:last-of-type {
        //     margin-block-end: 10px;

        // }
    }

    .btn-checklist-save {
        background-color: $t-btn-bgc;
        color: $clr6;
        margin-inline-end: 4px;
        width: 52px;
        height: 32px;
        font-size: 14px;
        padding: 4px 12px 6px;


        &:hover {
            background-color: #026aa7;
        }
    }

    .btn-add-todo-cancel {
        width: 64.9px;
        height: 32px;
        border-radius: 3px;

        &:hover {
            background-color: $t-hover3;
        }
    }
}