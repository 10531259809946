.board-side-menu {
    box-shadow: 0 12px 24px -6px #091e4240,0 0 0 1px;
    transform: translateX(0);
    
    position: fixed;
    right: 0;
    top: 45px;
    height: 100%;
    
    background-color: #f4f5f7;
    transition-duration: .1s;
    transition-property: transform,width;
    transition-timing-function: ease-in;
    width: 339px;
    z-index: 400;

    display: flex;
    flex-direction: column;
    padding:0 10px;

    .board-side-menu-header {
        width: 100%;
        display: flex;
        border-bottom: 1px solid rgba(9, 30, 66, 0.129);

        .board-side-menu-header-title {
            color: #172b4d;
            flex: 1;
            line-height: 20px;
            margin: 14px 32px;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            transition: margin .12s ease-in;
            white-space: nowrap;

            a.header-unsplash-link {
                cursor: pointer;
                text-decoration: underline;

                &:visited {
                    color: inherit;
                }
            }
        }

        a.board-side-menu-header-cancel {
            background-color: unset;
            color: #42526e;
            border: none;
            margin: 0 2px;
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 6px;
            top: 8px;

            &:hover {
                cursor: pointer;
            }

            .icon-close {
                font-size: 24px;
                text-align: center;
                
                svg {
                    stroke-width: 0;
                }
            }
        }

        a.board-side-menu-header-go-back {
            color: #42526e;
            border: none;
            margin: 0 2px;
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 6px;
            top: 8px;
            &:hover {
                cursor: pointer;
            }

            .icon-go-back{
                font-size: 24px;
                text-align: center;
                    
                    svg {
                        stroke-width: 0;
                    }
            }
        }
    }

    .board-side-menu-content {
        margin: 8px 0;
        flex-grow: 0.9;

        .side-menu-main{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            button {
                background-color:inherit;
                width: 100%;
                height: 30px;
                border-radius: 3px;
                border: none;
                outline: none;
                color: #172b4d;
                line-height: 30px;
                font-weight: 600;
                font-size: 14px;
                font-family: 'Segoe UI';
                text-align: start;
                display: flex;
                align-items: center;
                &:hover {
                    background-color: #091e4214;
                    cursor: pointer;
                }
                &:active {
                    background-color: #e4f0f6;
                }
            }

            hr.side-menu-separator {
                height: 1px;
                border: 0;
                border-top: 1px solid rgba(9, 30, 66, 0.129);
                margin: 8px 0;
                padding: 0;
                width: 100%;
            }

            .board-activity-log {
                width: 100%;
                .board-activity-log-header {
                    background-color:inherit;
                    width: 100%;
                    height: 30px;
                    color: #172b4d;
                    line-height: 30px;
                    font-weight: 600;
                    font-size: 14px;
                    font-family: 'Segoe UI';
                    text-align: start;
                    padding: 0 6px;
                }

                .board-activity-list {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;
                    width: 100%;
                    margin-top: 12px;
                    padding: 0 6px;
            
                    .board-activity-preview {
                        width: 100%;
                        display: grid;
                        grid-template-columns: 32px 1fr;
                        color: #172b4d;
            
                        .preview-member-img {
                            width: 32px;
                            border-radius: 50%;
                        }
            
                        .board-activity-description {
                            display: flex;
                            flex-direction: column;
                            word-break: break-word;
                            line-height: 20px;
                            
                            p {
                                margin: 0;
                            }
                        
                            .username {
                                font-weight: 700;
                                font-size: 14px;
                                margin-inline-end: 3px;
                                color: #172b4d;
                                line-height: 20px;
                            }

                            .activity-txt {
                                font-weight: 400;
                                font-size: 14px;
                                color: #172b4d;
                                line-height: 20px;
                                height: fit-content;
                                word-break: break-word;
                            }

                            .time {
                                font-weight: 400;
                                font-size: 12px;
                                color: #5e6c84;
                                margin-left: 8px;
                            }

                            .comment {
                                margin-top: 3px;
                                width: 100%;
                                min-height: 36px;
                                border-radius: 3px;
                                box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
                                background-color: white;
                                padding: 8px 12px;
                                margin-bottom: 6px;
                                color: #172b4d;
                            }
                        }
                    }
                }
            }
        }

        .side-menu-bg-options {
            color: #172b4d;
            display: flex;
            justify-content: space-between;
            gap:8px;
            width: 100%;

            .option {
                width: 100%;
                &:hover {
                    cursor: pointer;
                }

                .option-img {
                    background-color: #dfe1e6;
                    background-size: cover;
                    border-radius: 8px;
                    height: 96px;
                    margin-bottom: 8px;
                    width: 100%;
                    &:hover {
                        opacity: 0.7;
                    }
                }
                .bg-photos {
                    background-image: url("https://a.trellocdn.com/prgb/dist/images/photos-thumbnail@3x.8f9c1323c9c16601a9a4.jpg");
                }
                .bg-colors {
                    background-image: url("https://a.trellocdn.com/prgb/dist/images/colors@2x.ec32a2ed8dd8198b8ef0.jpg");
                }
                
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    margin:0;
                }
            }
        }

        .side-menu-colors{
            
            ul.color-list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(5, 96px);
                gap: 8px;

                li {
                    border-radius: 8px;
                    &:hover {
                        cursor: pointer;
                        opacity: 0.7;
                    }
                }
            }
        }

        .side-menu-photos{
            height: 100%;
            overflow: hidden;

                input.search-photos-input {
                    background: #fafbfc;
                    width: 100%;
                    border: none;
                    border-radius: 3px;
                    box-shadow: inset 0 0 0 2px #dfe1e6;
                    box-sizing: border-box;
                    display: block;
                    line-height: 20px;
                    margin-bottom: 12px;
                    outline: none;
                    padding: 8px 12px;
                    transition-duration: 85ms;
                    transition-property: background-color,border-color,box-shadow;
                    transition-timing-function: ease;
                }

            .photo-list-wrapper {
                height:75vh;
            
                ul.photo-list {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: repeat(15, 96px);
                    gap: 8px;
                    height: 100%;
                    overflow-x: hidden;
                    overflow-y: auto;
                    position: relative;
                    padding-right: 4px;

                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                        margin: 5px;
                    }
            
                    &::-webkit-scrollbar-button {
                        // width: 38px;
                        // height: 38px;
                    }
            
                    &::-webkit-scrollbar-thumb {
                        background: #BFC4CE;
                        border: none;
                        border-radius: 4px;
                    }
            
                    &::-webkit-scrollbar-thumb:hover {
                        background: #BFC4CE;
                    }
            
                    &::-webkit-scrollbar-thumb:active {
                        background: #BFC4CE;
                    }
            
                    &::-webkit-scrollbar-track {
                        background: #dadbe2;
                        border: none;
                        border-radius: 4px;
                    }
            
                    &::-webkit-scrollbar-track:hover {
                        background: #dadbe2;
                    }
            
                    &::-webkit-scrollbar-track:active {
                        background: #dadbe2;
                    }
            
                    &::-webkit-scrollbar-corner {
                        background: transparent;
                    }



                    li {
                        border-radius: 8px;
                        &:hover {
                            cursor: pointer;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }
}
