// Font Sizes
.fs12 {
    font-size: rem(12px);
}

.fs14 {
    font-size: rem(14px);
}

.fs18 {
    font-size: rem(18px);
}

.fs20 {
    font-size: rem(20px);
}

.fs24 {
    font-size: rem(24px);
}

.fs28 {
    font-size: rem(28px);
}

.fs30 {
    font-size: rem(30px);
}

// flex helpers

.flex {

    display: flex;

    >*.full {
        flex: 1;
    }

    &.column {
        flex-direction: column;
    }

    &.justify-center {
        justify-content: center;
    }

    &.align-center {
        align-items: center;
    }
}

// Media helpers

.square-ratio {
    @include aspect-ratio(1, 1);
}

.video-ratio {
    @include aspect-ratio(16, 9);
}

.portrait-ratio {
    @include aspect-ratio(9, 16);
}



.clean-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.clean-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.hide {
    display: none;
}



.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px;
    // width: 300px;
    height: 80vh;


    img.loader {
        // padding: 100px;
        width: 50px;
        height: 50px;
    }
}

.loader-wrapper-task-deatils {
    background-color: $t-bg1;
    margin: auto;
    grid-column: 2;

}