.quick-task-edit {
    z-index: 200;
    position: fixed;
    margin: 0;

    .quick-task-edit-screen {
        z-index: 210;
        position: fixed;
        inset: 0;
        background-color: #1212126a;
        opacity: 0;
        transition: opacity 0.6s;
        pointer-events: none;
        max-height: 100%;
        pointer-events: auto;
        opacity: 1;
        cursor: auto;
    }

    .open-task {
        z-index: 220;
        width: 260px;
        position: fixed;

        form {

            .open-task-title {
                font-family: segoe_ui;
                text-align: left;
                vertical-align: auto;
                border: none;
                background-color: #ffffff;
                box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;
                padding: 8px 12px;
                font-size: 0.875rem;
                width: 100%;
                min-height: 124px;
                color: #172b4d;
                outline: none;
                border-radius: 3px;
                resize: none;
                cursor: text;
            }

            button {
                position: absolute;
                top: cal(124px + 2px);
                font-size: 14px;
                background-color: #046AA7;
                cursor: pointer;
                background-color: #0079bf;
                border: none;
                box-shadow: none;
                border-radius: 3px;
                color: #fff;
                height: 32px;
                width: auto;
                padding: 7px 24px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
            }
        }  
    }

    .quick-edit-btns {
        position: relative;
        top: 10px;
        left: 260px;
        z-index: 220;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        .quick-btn  {
            background-color:rgba(0, 0, 0, 0.652);
            font-family: noto-sans-hebrew-regular;
            text-align: left;
            border-radius: 3px;
            color: #c7d1db;
            display: block;
            width: fit-content;
            float: left;
            margin: 0 0 4px 8px;
            padding: 6px 12px;
            border: none;
            display: flex;
            align-items: center;
            gap: 5px;
            transition: all 150ms ease-out 0s;

            svg {
                color: #c7d1db;
                height: 13px;

                path {
                    fill: #c7d1db;
                }
            }

            &:hover {
                background-color:rgba(0, 0, 0, 0.902);
                transform: translateX(5px);
            }

            .tag-svg, .cover-svg {
                transform: rotate(270deg);
            }

            .copy-svg {
                    path {
                        stroke: #c7d1db;
                        fill: transparent;
                        // fill:#c7d1db;
                    }
            }
        }
    }
}