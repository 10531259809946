.dynamic-modal {
    position: fixed;
    z-index: 1000;

    .dynamic-modal-wrapper {
        background: #ffffff;
        border-radius: 3px;
        box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px, #091e4214;
        overflow: hidden;
        width: 304px;
        z-index: 70;

        .close-icon {
            position: absolute;
            top: 10px;
            // right: 10px;
            left: 276px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            color: #5e6c84;
        }

        .dynamic-modal-title {
            text-align: center;
            border-bottom: 1px solid #091e4221;
            box-sizing: border-box;
            color: #5e6c84;
            display: block;
            line-height: 40px;
            margin: 0 12px;
            padding: 0 32px;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: capitalize;
            cursor: default;
            font-size: 14px;
        }

        .dynamic-modal-container {
            padding: 12px;

            .cmp-dynamic-input {
                background-color: #fafbfc;
                width: 100%;
                border: none;
                border-radius: 3px;
                box-shadow: inset 0 0 0 2px #dfe1e6;
                display: block;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 10px;
                // letter-spacing: 0.08rem;

                &::placeholder {
                    color: $t-txt2;
                    font-family: San_francisco_light;
                }

                &:focus {
                    box-shadow: rgb(0, 121, 191) 0px 0px 0px 2px inset;
                }
            }

            .cmp-dynamic-options-title {
                padding-top: 1px;
                margin-block-end: 7px;
                // background-color: aquamarine;

            }

            .cmp-dynamic-options-list {

                .cmp-dynamic-option {
                    display: flex;
                    margin: 0;
                    padding: 0;
                    margin-block: 9px;
                    align-items: center;
                    padding-inline-start: 5px;
                    width: 100%;
                }
            }

        }


    }


    .small-headline {
        font-size: 12px;
        color: #66748b;
        font-family: segoe_ui;
        letter-spacing: 0.04em;
        width: fit-content;
        cursor: text;
        @include margin-titles-task
    }

    .medium-headline {
        font-size: 16px;
        color: #2d3f5d;
        margin: 0;
        font-family: segoe_ui;
        font-weight: bold;
        letter-spacing: 0.03em;
        cursor: text;
    }


    .btn-task-details {
        padding-inline: 12px;
        padding-block: 6px;
        color: $t-txt1;
        background-color: $t-bg5;
        border-radius: 3px;
        transition: all 150ms ease-out 0s;

        &:hover {
            background-color: $t-hover3;
        }
    }

    .task-details-edit-item {
        display: flex;
        align-items: flex-end;
        padding-inline-start: 40px;
        flex-wrap: wrap;
    }

    .icon-task {
        width: 32px;
        height: 32px;

    }

    .btn-task-exit {
        width: 44px;
        height: 44px;
        position: absolute;
        justify-self: end;
        border-radius: 50%;
        display: flex;
        color: #42526e;
        padding: 4px;
        margin: 4px;
        transition: all 150ms ease-out 0s;

        &:hover {
            background-color: $t-hover3;
        }

        &.close-hover {
            background-color: $t-hover3;
        }

        .exit-icon {
            width: 20px;
            height: 20px;
            line-height: 32px;
            padding: 0;
            margin: auto;
            font-size: 14px;
        }
    }



    textarea,
    input {
        // font-family: inherit;
        resize: none;
        // background-color: inherit;
        border: none;
        // border-radius: 3px;
        // margin: 0px;
        cursor: pointer;

        color: $t-txt1;
        letter-spacing: 0.03em;

        &:focus-visible {
            outline: none;
        }

        &:focus {
            border: none;
            // box-shadow: rgb(0, 121, 191) 0px 0px 0px 2px inset;
            background-color: $t-bg3;
        }
    }

}