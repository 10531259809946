* {
    box-sizing: border-box;
}

html {
    padding: 0;
    margin: 0;
}


html {
    font-family: montserrat;
    line-height: 1.6;
    color: $clr2;
}

body {
    font-size: rem(18px);
    margin: auto;
    font-family: 'Segoe UI';
    max-height: 100vh;
}

.app {
    height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 16px;
}

h1 {
    font-size: 18px;
    font-weight: 700;
}

h2 {
    font-size: 14px;
    font-weight: 600;
}

img {
    width: 100%;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}