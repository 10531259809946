.group-list-container {
    display: flex;
    width: fit-content;

    .btn-group {
        // position: absolute;
        right: 0;
        // z-index: 200;
        border: none;
        background-color: unset;
        border-radius: 3px;
        display: flex;
        align-items: center;
        padding: 3px;
        height: 28px;
        width: 28px;

        svg {
            height: 16px;
            width: 16px;
            border-radius: 3px;
            border: 0;
            align-items: center;
            background-color: transparent;
            margin: 0 4px;
            white-space: nowrap;
            pointer-events: none;

            path {
                fill: $t-txt3;
            }

            &:hover {
                path {
                    fill: #42526e;
                }
            }
        }

        &:hover {
            background-color: #dadbe2;
        }
    }

    .group-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, 272px);
        grid-auto-flow: column;
        gap: 8px;
        // margin: 8px;
        height: 100%;
        // margin-right: -23px;

        .group-wrapper {
            width: 272px;
            height: fit-content;
            max-height: calc(100vh - 128px);
            overflow-y: hidden;
            overflow-x: hidden;
            background-color: #ebecf0;
            border-radius: 3px;
            // margin: 0 4px;
            padding: 0 4px 8px 4px;
            display: flex;
            flex-direction: column;

            .group-top {
                height: 40px;
                padding: 8px 4px 4px 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: fit-content;
                position: relative;
                color: $b-txt1;

                textarea {
                    width: 100%;
                    font-family: inherit;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    height: 28px;
                    resize: none;
                    overflow: hidden;
                    overflow-wrap: break-word;
                    margin: -4px 0;
                    padding: 4px 8px 4px 3px;
                    background-color: inherit;
                    border: none;
                    border-radius: 3px;
                    color: $b-txt1;

                    &:focus-visible {
                        outline: none;
                    }

                    &:focus {
                        border: none;
                        box-shadow: rgb(0, 121, 191) 0px 0px 0px 2px inset;
                        background-color: $t-bg3;
                        min-height: 20px;
                        max-height: 256px;
                    }
                }
            }
        }
    }

    .add-new-group {
        width: 272px;
        height: 32px;
        padding: 0 4px 4px 8px;
        transition: background 85ms ease-in, opacity 40ms ease-in, border-color 85ms ease-in;

        .placeholder {
            font-size: 14px;
            line-height: 26px;
            color: white;
            padding: 6px 8px;
            margin: 0;
            transition: color 85ms ease-in;
            display: flex;
            align-items: center;
            background-color: rgb(255 255 255 / 24%);
            cursor: pointer;
            border-radius: 3px;
            height: fit-content;
            min-height: 32px;

            .icon-plus {
                font-size: 16px;
                text-align: center;
                margin: 2px 2px;
            }

            &:hover {
                background-color: rgb(255 255 255 / 30%);
            }
        }

        form.add-group-form {
            background-color: #ebecf0;
            border-radius: 3px;
            height: fit-content;
            min-height: 32px;
            padding: 4px;
            transition: background 85ms ease-in, opacity 40ms ease-in, border-color 85ms ease-in;
            margin: 0 0;

            input {
                width: 100%;
                background-color: $t-bg3;
                border: none;
                border-radius: 3px;
                box-shadow: inset 0 0 0 2px #dfe1e6;
                line-height: 20px;
                display: block;
                margin-bottom: 12px;
                margin-top: 5px;
                outline: none;
                padding: 8px 12px;
                transition-duration: 85ms;
                transition-property: background-color, border-color, box-shadow;
                transition-timing-function: ease;
                font-family: inherit;
                font-size: 14px;
                height: 36px;

                &:focus {
                    border: none;
                    box-shadow: rgb(0, 121, 191) 0px 0px 0px 2px inset;
                    background-color: $t-bg3;
                }
            }

            .add-group-controls {
                display: block;
                display: flex;
                align-items: center;

                .add-group {
                    background-color: #0079bf;
                    border: none;
                    box-shadow: none;
                    border-radius: 3px;
                    color: #fff;
                    height: 32px;
                    padding: 4px 12px;
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 400;

                    &:hover {
                        background-color: #026aa7;
                        text-decoration: none;
                    }

                    &:active {
                        background-color: #055a8c;
                    }
                }

                .cancel {
                    background-color: unset;
                    color: $t-txt3;
                    border: none;
                    margin: 0 2px;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                        color: #172b4d;
                    }

                    .icon-close {
                        font-size: 28px;
                        text-align: center;
                    }
                }
            }
        }
    }
}