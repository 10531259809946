.cmp-dynamic-option-labels {
    width: 248px;
    border-radius: 3px;
    margin-block-end: 5px;
    padding-block: 0;
    width: 100%;

}

.cmp-dynamic-labels-checkbox {

    box-shadow: inset 0 0 0 2px #dfe1e6;
    border: none;
    cursor: pointer;

    &:active {
        box-shadow: inset 0 0 0 2px #dfe1e6;
        border: none;
    }

    &:focus {
        box-shadow: inset 0 0 0 2px #dfe1e6;
        border: none;
    }

    &:checked {
        box-shadow: none;
    }
}

.btn-create-label {
    font-size: 14px;
}

.cmp-dynamic-labels-label {
    flex-grow: 1;
    margin-inline-start: 12px;
    display: flex;
    align-items: center;
    padding-inline-start: 10px;
    font-size: 14px;
    color: $t-txt1;

    .color-circle {
        margin-inline-end: 10px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        margin-block: 8px;
    }


}

.btn-edit-label {
    margin-inline-start: 0.2em;
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    color: $t-txt1;

    &:hover {
        background-color: $t-hover3;
    }

    svg {
        width: 14px;
        height: 14px;
    }
}


.btn-edit-label-modal {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .btn-update-label {
        background-color: $t-btn-bgc;
        color: $clr6;

        &:hover {
            background-color: #026aa7;
        }
    }

    .btn-remove-label {
        background: #b04632;
        color: $clr6;

        &:hover {
            background-color: #933b27;
        }
    }
}