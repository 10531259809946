.task-description-container {
    display: grid;
    grid-template-columns: 40px 1fr;
    // margin-top: ;

    .description-icon {
        padding-block-start: 3px;
    }

    .task-description-title-container {
        display: flex;
        align-items: center;


        .btn-description-edit {

            width: 47.46px;
            margin-inline-start: 5.5px;
        }
    }

    .task-description-p {
        grid-column: 2;
        margin-block-start: 8px;
    }

    .task-description-placeholder {
        grid-column: 2;
        margin: 0;
        background-color: $t-bg5;
        height: 56px;
        width: 100%;
        padding-inline: 12px;
        padding-block: 8px;
        margin-block-start: 8px;
    }

    .task-description-textarea {
        height: 108px;
        width: 512px;
        padding-inline: 12px;
        padding-block: 8px;
        font-family: San_francisco_light;
        margin-inline-start: 40px;
        letter-spacing: 0.07em;
        background-color: $clr6 ;
        border-radius: 2px;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            background-color: none;
        }

        &:focus {
            box-shadow: inset 0 0 0 2px #0079bf;
            background-color: $clr6 ;
        }

    }



    .task-description-btn {
        margin-inline-start: 40px;
        margin-block-start: 8px;
        display: flex;
        align-items: center;
    }

    .btn-description-save {
        background-color: $t-btn-bgc;
        color: $clr6;
        margin-inline-end: 4px;
        width: 52.6px;
        padding: 4px 12px 6px;

        &:hover {
            background-color: #026aa7;
        }
    }

    .btn-description-cancel {
        background-color: initial;
        width: 64.9px;


        &:hover {
            background-color: $t-hover3;
        }
    }


    .btn-description-save,
    .btn-description-cancel,
    .btn-description-edit {
        height: 32px;

    }


}