.main-header-demo {
    height: 44px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $wh-bg1;
    background-color: #026aa7;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    button {
        border: none;
        background-color: transparent;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        font-family: segoe_ui;
        margin: 0 3px;
        padding: 0;
        font-size: 0.9rem;
        color: $wh-txt1;
        border-radius: 3px;

        &.nav-btn {
            padding-left: 18px;
        }
        &.create-btn {
            padding: 5px 8px 8px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 3px;
            transition: all 150ms ease-out 0s;
            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }

        img {
            height: 24px;
            width: 24px;
            line-height: 24px;
        }
    }

    button:hover {
    background-color: #025586;
    }
    button:active {
        background-color: rgba(255, 255, 255, 0.2);
    }
    svg {
        height: 20px;
        width: 20px;
        border-radius: 3px;
        border: 0;
        align-items: center;
        background-color: transparent;
        margin: 6px;
        white-space: nowrap;
        color: $wh-txt1;
        path {
            fill: $wh-txt1;
        }
    }

    .logo-nav {
        display: flex;
        align-items: center;
        margin-left: 4px;

        .header-logo {
            display: flex;
            align-items: center;

            svg {
                transform: rotate(180deg);
            }

            h1 {
                font-size: 1.2rem;
                font-family: charlie-semibold;
                letter-spacing: 0.03rem;
                color: $wh-txt1;
            }
        }
    }


    .left-nav {
        display: flex;
        align-items: center;
        margin-right: 6px;

        .search {
            height: 32px;
            width: 100%;
            width: 200px;
            padding: 0px 4px;
            border-radius: 3px;
            font-size: 0.9rem;
            line-height: 20px;
            background-color: rgba(255, 255, 255, 0.2);
            color: $wh-txt1;
            justify-content: flex-start;
            border: 1px solid rgba(255, 255, 255, 0.25);
            box-shadow: none;
            cursor: text;
            svg {
                height: 16px;
                width: 16px;
            }
        }

        .search:active,
        .search:focus {
            cursor: text;
            background-color: rgba(255, 255, 255, 0.525);
        }
    }
}