.task-members-container {
    padding-inline-end: 15px;
    margin-top: 4px;

    h3 {
        text-transform: capitalize;
    }

    .members-list {
        .member-img {
            cursor: pointer;
            margin-inline-end: 4px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }

    .add-members {
        background-color: $t-bg5;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        cursor: pointer;

        &:hover {
            background-color: $t-hover3;
        }

        .add-members-icon {
            // color: $t-pls-clr;
            color: #6b7485;
            margin: auto;
            font-size: 18px;

        }

    }

    li {
        float: left;
    }
}