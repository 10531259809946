.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9fafc;
    height: 100vh;

    .form-logo {
        color:$h-txt2;
        font-family: charlie-bold;
        width: fit-content;
        height: 45px;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
        margin-right: 15px;
        align-self: end;
        }

        p.logo-txt {
        font-size: 3.3em;
        font-family: charlie-bold;
        letter-spacing: 1px;
        }
    }

    hr.bottom-form-separator {
        height: 1px;
        border: 0;
        border-top: 1px solid hsl(0, 0%, 80%);
        margin: 1em 0;
        padding: 0;
        width: 100%;
    }
}

.wrong-credentials {
    border-radius: 3px;
    box-shadow: rgb(9 30 66 / 28%) 0px 4px 8px -2px,
        rgb(9 30 66 / 30%) 0px 0px 1px;
    color: rgb(37, 56, 88);
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 24px;
    padding: 16px;
    transform: rotateX(0deg);
    transform-origin: 50% 0px;
    transition-property: visibility, height, margin-bottom, opacity, transform,
        padding;
    transition-duration: 0s, 0.2s, 0.2s, 0.2s, 0.2s;
    transition-timing-function: ease-in-out;
    width: 100%;
    text-align: center;
    color: #E53E3E;

    &.not-visible {
        height: 0px;
        margin-bottom: 0px;
        opacity: 0;
        transform: rotateX(-90deg);
        visibility: hidden;     
        display: none;   
    }
}

.signup-form {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    padding: 32px 40px;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    box-sizing: border-box;
    color: #5e6c84;
    z-index: 10;
    // position: absolute;
    // top: 150px

    h1 {
        font-weight: bold;
        font-size: 1em;
        text-align: center;
        color: #5e6c84;
        font-size: 16px;
        letter-spacing: -0.01em;
        line-height: 28px;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    a {
        font-family: 'Segoe UI';
        font-size: 14px;
        font-weight: 300;
        margin: auto;
        color: #0052cc;
        text-align: center;
        &:hover {
        text-decoration: underline;
        }
    }

    input:focus {
        outline: none;
        border: 2px solid black;
    }

    input[type='password'],
    input[type='email'],
    input[type='text'],
    input[type='number'] {
        color: #4d4d4d;
        font-size: 14px;
        font-weight: 300;
        font-family: 'Segoe UI';
        border: 2px solid #dfe1e6;
        background-color:#fafbfc;
        border-radius: 3px;
        padding: 0.65rem 0.5rem;
        margin: 0;
        width: 100%;
        height: 40px;
        appearance: none;
        display: block;
        transition: background-color, border-color 0.5s;
        &:focus {
        background-color: white;
        border-color: #0052cc;
        }
    }

    input[type='submit'],
    input[type='reset'],
    input[type='button'],
    button {
        padding: 0.5rem 1.25rem;
        font-size: 1rem;
        border-radius: 10px;
        background-color: #fafbfc;
        color: white;
        text-decoration: none;
        font-weight: bold;
        margin-bottom: 1rem;
        display: inline-block;
        transition: background-color 100ms ease-in-out, border 100ms ease-in-out,
        transform 100ms ease-in-out;
        width: 100%;
        margin: auto;
    }

    .error {
        font-size: 12px;
        color: #E53E3E;
        margin-bottom: 15px;
        margin-top: 3px;
        width: 100%;
        text-align: start;
    }

    span.empty-space {
        height: 0px;
    }

    button[type='submit'] {
        border: none;
        font-size: 14px;
        background-color: #0052cc;
        color: white;
        border-radius: 5px;
        padding: 10px 0;
        &:hover {
        background-color: lighten(#0052cc, 8%);
        }
    }

    .already-have-account {
        font-size: 14px;
    }
}

.left-hero {
    display: inline-block;
    position: absolute;
    width: 400px;
    bottom: 0;
    left: 0;
    max-width: 30%;
}

.right-hero {
    display: inline-block;
    position: absolute;
    width: 400px;
    bottom: 0;
    right: 0;
    max-width: 30%;
}
