.task-move-modal-section {

    .task-move-modal-options {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .move-modal-select-list-container,
        .move-modal-select-pos-container {
            float: left;
            height: 50px;
            margin-top: 0;
            margin-right: 8px;
            position: relative;
            flex: 3;
            background-color: #091e420a;
            border-radius: 3px;
            // padding: 4px;
            padding: 6px 12px;
            text-align: left;

            &:hover {
                background-color: $t-hover3;
            }

            .move-modal-label {
                color: var(--ds-text-subtle, #5e6c84);
                display: block;
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 0;
                padding-inline-start: 5px;
            }

            .move-modal-select {
                cursor: pointer;
                display: block;
                height: 32px;
                overflow: hidden;
                padding: 0 12px;
                border: none;
                background-color: transparent;
                width: 100%;
                font-family: inherit;
                color: $b-txt1 ;
                font-size: 15px;
                height: fit-content;
                padding-inline-start: 0px;
                margin-inline-start: 0px;
                appearance: none;

                &:focus-visible {
                    outline: none;
                }




                .move-modal-select-option {
                    display: block;
                    font-size: 14px;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border: none;
                    color: #172b4d;
                    font-size: 14px;
                    line-height: 20px;

                    &:focus {
                        border: none;
                    }
                }
            }
        }

        .move-modal-select-pos-container {
            flex: 1;
        }

    }

    .btn-move-modal {
        margin-top: 12px;
        background-color: $t-btn-bgc;
        color: $clr6;
        height: 32px;
        font-size: 14px;
        padding: 0 24px;

        &:hover {
            background-color: #046AA7;
        }
    }
}