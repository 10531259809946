.board {
    // background-color: rgb(0 121 191);
    background-repeat: no-repeat;
    background-size: cover;
    color: $b-txt2;
    height: 100%;
    height: calc(100vh - 45px);
    display: flex;
    flex-direction: column;
    // background: linear-gradient(to bottom,#0000003d,#00000000 80px,#00000000);
    // mask-image: linear-gradient(to bottom,#0000003d,#00000000 80px,#00000000);
    // -webkit-mask-image: linear-gradient(to bottom,rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.7) 80px,rgba(0, 0, 0, 0.7));
    
    h1 {
        font-size: 18px;
        line-height: 32px;
        padding: 0 12px;
    }

    .board-bg-gradient {
        background: linear-gradient(to bottom,rgb(0 0 0 / 0.05),rgb(0 0 0 / 0) 80px,rgb(0 0 0 / 0));
        
    }
    
    .board-main-content {
        overflow-x: scroll;
        padding-left: 12px;
        margin-bottom: 8px;
        flex-grow: 1;
        max-height: calc(100vh - 100px);
        &::-webkit-scrollbar {
            width: 12px;
            height: 12px;
        }
        &::-webkit-scrollbar-button {
            width: 38px;
            height: 38px;
        }
        &::-webkit-scrollbar-thumb {
            background: #ffffff70;
            border: none;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #ffffff70;
        }
        &::-webkit-scrollbar-thumb:active {
            background: #ffffff70;
        }
        &::-webkit-scrollbar-track {
            background: #00000026;
            border: none;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-track:hover {
            background: #00000026;
        }
        &::-webkit-scrollbar-track:active {
            background: #00000026;
        }
        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }

    .board-top-menu-dark-bg {
        color: $b-txt2;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 4px 6px 9px;
        background: linear-gradient(to bottom,#0000003d,#00000000 48px,#00000000);
    
        box-shadow: 0px 40px 20px 3px rgb(0 0 0 / 15%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) 48px, rgba(0, 0, 0, 0.15));

        button.btn-board {
            color: $b-txt2;
            background-color: rgb(255 255 255 / 25%);
            border-radius: 3px;
            border: none;
            font-size: 14px;
            height: 32px;
            line-height: 32px;
            max-width: 400px;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            text-overflow: ellipsis;
            transition: .1s ease;
            display: flex;
            align-items: center;
            &:hover {
                background-color: rgb(255 255 255 / 35%);
            }
            &:active {
                background-color: rgb(255 255 255 / 45%);
            }
        }

        span {
            border-left: 1px solid rgb(255 255 255 / 16%);
            float: left;
            height: 16px;
            margin: 0 8px;
        }

        .board-top-menu-left {
            display: flex;
            align-items: center;

            .board-title {
                cursor: pointer;
                font-size: 18px;
                font-weight: 700;
                line-height: 32px;
                // max-width: calc(100% - 24px);
                // width: fit-content;
                // text-overflow: ellipsis;
                overflow: hidden;
                padding: 0;
                text-decoration: none;
                white-space: nowrap;
                transition: .1s ease;
                border-radius: 3px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 4px;
                &:hover {
                    background-color: rgb(255 255 255 / 35%);
                    cursor: pointer;
                }

                input {
                    color: $b-txt2;
                    font-family: inherit;
                    height: 32px;
                    resize: none;
                    overflow: hidden;
                    background-color: inherit;
                    border: none;
                    border-radius: 3px;

                    font-size: 18px;
                    font-weight: 700;
                    line-height: 32px;
                    padding: 0 ;
                    margin: 0 auto;
                    text-align: center;
                    &:hover {
                        cursor: pointer;
                    }
                    &:focus-visible {
                        outline: none;
                    }
                    &:focus {
                        color: $b-txt1;
                        border: none;
                        box-shadow: rgb(0, 121, 191) 0px 0px 0px 2px inset;
                        background-color: $t-bg3;
                        min-height: 20px;
                        max-height: 256px;
                    }
                }
            }

            button.btn-board.star-false {
                left: 0;
                padding: 5px 6px 5px 6px;
                top: 0;
                svg {
                    height: 20px;
                    line-height: 20px;
                    width: 20px;
                    stroke-width: 2.3;
                }
                &:hover {
                    color: #f2d600;
                    svg {
                        stroke-width: 2.5;
                    }
                }
                &:active {
                    color: #f2d600;
                    svg {
                        path {
                            fill: #f2d600;
                        }
                    }
                }
            }

            button.btn-board.star-true {
                left: 0;
                padding: 5px 6px 5px 6px;
                top: 0;
                color: #f2d600;
                svg {
                    height: 20px;
                    line-height: 20px;
                    width: 20px;
                    stroke-width: 2.3;
                    path {
                        fill: #f2d600;
                    }
                }
                &:hover {
                    color: #f2d600;
                    svg {
                        stroke-width: 2.5;
                        path {
                            fill: none
                        }
                    }
                }
                &:active {
                    color: $b-txt2;
                    svg {
                        path {
                            fill: none
                        }
                    }
                }
            }
        }
        
        .board-top-menu-right {
            display: flex;
            align-items: center;
            &.open {
                margin-right: 340px;
            }

            button.btn-board.menu {
                align-items: center;
                &.open {
                    display: none;
                }
                svg {
                    height: 20px;
                    line-height: 20px;
                    width: 20px;
                }
            }

            button.btn-board.remove {
                align-items: center;
                svg {
                    height: 20px;
                    line-height: 20px;
                    width: 20px;
                }
            }

            ul.board-top-menu-members {
                height: 100%;
                display: flex;
                align-items: center;
                direction: rtl;
                padding-right: 3px;
                
                li {
                    height: 30px;
                    margin-right: -4px;
                }
            }

            @media (max-width: 480px) {
                ul.board-top-menu-members {
                    display: none;
                }
            }
            
        }
    }

    .board-top-menu-light-bg {
        color: $b-txt1;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 4px 6px 9px;
        box-shadow: 0px 40px 20px 3px rgb(0 0 0 / 15%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) 48px, rgba(0, 0, 0, 0.15));

        button.btn-board {
            color: $b-txt1;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            border: none;
            font-size: 14px;
            height: 32px;
            line-height: 32px;
            max-width: 400px;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            text-overflow: ellipsis;
            transition: .1s ease;
            display: flex;
            align-items: center;
            &:hover {
                background-color: rgba(0, 0, 0, 0.2);
                
            }
            &:active {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }

        span {
            border-left: 1px solid rgba(0, 0, 0, 0.2);
            float: left;
            height: 16px;
            margin: 0 8px;
        }

        .board-top-menu-left {
            display: flex;
            align-items: center;

            .board-title {
                cursor: pointer;
                font-size: 18px;
                font-weight: 700;
                line-height: 32px;
                // max-width: calc(100% - 24px);
                // width: fit-content;
                // text-overflow: ellipsis;
                overflow: hidden;
                padding: 0;
                text-decoration: none;
                white-space: nowrap;
                transition: .1s ease;
                border-radius: 3px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 4px;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                }

                input {
                    color: $b-txt1;
                    font-family: inherit;
                    height: 32px;
                    resize: none;
                    overflow: hidden;
                    background-color: inherit;
                    border: none;
                    border-radius: 3px;

                    font-size: 18px;
                    font-weight: 700;
                    line-height: 32px;
                    padding: 0 ;
                    margin: 0 auto;
                    text-align: center;
                    &:hover {
                        cursor: pointer;
                    }
                    &:focus-visible {
                        outline: none;
                    }
                    &:focus {
                        color: $b-txt1;
                        border: none;
                        box-shadow: rgb(0, 121, 191) 0px 0px 0px 2px inset;
                        background-color: $t-bg3;
                        min-height: 20px;
                        max-height: 256px;
                    }
                }
            }

            button.btn-board.star-false {
                left: 0;
                padding: 5px 6px 5px 6px;
                top: 0;
                color: $b-txt1;
                svg {
                    height: 20px;
                    line-height: 20px;
                    width: 20px;
                    stroke-width: 2;
                }
                &:hover {
                    color: #f2d600;
                    svg {
                        stroke-width: 2.5;
                    }
                }
                &:active {
                    color: #f2d600;
                    svg {
                        path {
                            fill: #f2d600;
                        }
                    }
                }
            }

            button.btn-board.star-true {
                left: 0;
                padding: 5px 6px 5px 6px;
                top: 0;
                color: #f2d600;
                svg {
                    height: 20px;
                    line-height: 20px;
                    width: 20px;
                    stroke-width: 2.3;
                    path {
                        fill: #f2d600;
                    }
                }
                &:hover {
                    color: #f2d600;
                    svg {
                        stroke-width: 2.5;
                        path {
                            fill: none
                        }
                    }
                }
                &:active {
                    color: $b-txt1;
                    svg {
                        path {
                            fill: none
                        }
                    }
                }
            }
        }
        
        .board-top-menu-right {
            display: flex;
            align-items: center;

            button.btn-board.menu {
                align-items: center;
                svg {
                    height: 20px;
                    line-height: 20px;
                    width: 20px;
                }
            }

            button.btn-board.remove {
                align-items: center;
                svg {
                    height: 20px;
                    line-height: 20px;
                    width: 20px;
                }
            }

            ul.board-top-menu-members {
                height: 100%;
                display: flex;
                align-items: center;
                direction: rtl;
                padding-right: 3px;
                
                li {
                    height: 30px;
                    margin-right: -4px;
                }
            }
            @media (max-width: 480px) {
                ul.board-top-menu-members {
                    display: none;
                }
            }
        }
    }
}