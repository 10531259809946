.dates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 80%;
    font-size: 14px;

    .react-datepicker.calendar {
        font-family: 'Segoe UI';
        font-size: 14px;
        background-color: transparent;
        color: #172b4d;
        border: none;
        // border-radius: 0.3rem;
        display: inline-block;
        position: relative;
        width: 100%;

        .react-datepicker__header {
            text-align: center;
            background-color: transparent;
            border: none;
            // border-bottom: 1px solid #aeaeae;
            // border-top-left-radius: 0.3rem;
            padding: 8px 0;
            position: relative;
        }

        .react-datepicker__day-names {
            margin-bottom: -8px;

            color: #6B778C;
            font-size: 11px;
            font-weight: 700;
            font-family: 'Segoe UI';

            .react-datepicker__day-name,
            .react-datepicker__day {
                color: #6B778C;
                font-family: 'Segoe UI';
                font-size: 11px;
                font-weight: 700;

                &:hover {
                    background-color:  #EBECF0;
                    color: #091E42;
                }
                &:active {
                    background-color: #DEEBFF;
                    color:  #091E42;
                }
            }
        }
    }

    .dates-actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button.btn-dates {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            padding: 6px 0;
            margin-bottom: 8px;

            &.save {
                background-color: #0079bf;
                box-shadow: none;
                border: none;
                color: #ffffff;
                width: 100%;
                &:hover {
                    background-color: #026aa7;
                }
                &:active {
                    background-color:  #055a8c;
                }
            }
            
            &.remove {
                background-color: #f5f6f8;
                box-shadow: none;
                border: none;
                color: #172b4d;
                width: 100%;
                &:hover {
                    background-color:rgba(9, 30, 66, 0.08);
                }
                &:active {
                    background-color:#e4f0f6;
                    color: #0079bf;
                }

            }
        }
    }

}