@font-face {
    font-family: charlie-regular;
    src: url(../setup/fonts/Charlie/CharlieDisplay-Regular.ttf);
}

@font-face {
    font-family: charlie-semibold;
    src: url(../setup/fonts/Charlie/CharlieDisplay-Semibold.ttf);
}

@font-face {
    font-family: charlie-bold;
    src: url(../setup/fonts/Charlie/CharlieDisplay-Bold.ttf);
}

@font-face {
    font-family: noto-sans-light;
    src: url(../setup/fonts/Noto_Sans/NotoSans-Light.ttf);
}

@font-face {
    font-family: noto-sans-bold;
    src: url(../setup/fonts/Noto_Sans/NotoSans-Bold.ttf);
}


@font-face {
    font-family: noto-sans-hebrew-regular;
    src: url(../setup/fonts/Noto_Sans_Hebrew/NotoSansHebrew-Regular.ttf);
}

@font-face {
    font-family: noto-sans-hebrew-bold;
    src: url(../setup/fonts/Noto_Sans_Hebrew/NotoSansHebrew-Bold.ttf);
}

@font-face {
    font-family: roboto-regular;
    src: url(../setup/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: roboto-bold;
    src: url(../setup/fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
    font-family: segoe_ui;
    src: url(../setup/fonts/Segoe_UI/SEGOEUI.TTF);
}

@font-face {
    font-family: segoe_ui_bold;
    src: url(../setup/fonts/Segoe_UI/FSegoe-UI-Bold.ttf);
}

@font-face {
    font-family: San_francisco_light;
    src: url(../setup/fonts/San-francisco/SF-light.otf);
}

@font-face {
    font-family: San_francisco_regular;
    src: url(../setup/fonts/San-francisco/SF-regular.otf);
}

@font-face {
    font-family: San_francisco_medium;
    src: url(../setup/fonts/San-francisco/SF-medium.otf);
}

@font-face {
    font-family: San_francisco_bold;
    src: url(../setup/fonts/San-francisco/SF-bold.otf);
}